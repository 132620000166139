<template>
  <div>
	<div style="margin: 25px 50px 0 50px;">
<!-- 		<div style="margin: 10px 0">
		  <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
		  <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
		  <el-button type="warning" @click="reset">重置</el-button>
		</div> -->
		<el-table :data="tableData" border="" :show-header="false">
		 <!-- <el-table-column type="selection" width="55"></el-table-column> -->
		  <!-- <el-table-column prop="id" label="ID" width="80" sortable></el-table-column> -->
		          <el-table-column prop="getTime" ></el-table-column>
		  <el-table-column prop="title" ></el-table-column>
<!-- 		  <el-table-column prop="content" label="内容"></el-table-column>
		  <el-table-column prop="jieshou" label="接收者"></el-table-column>
		  <el-table-column prop="fasong" label="发送者"></el-table-column> -->
		
		  <el-table-column label=""  width="280" align="center">
		    <template slot-scope="scope">
		      <el-button type="success" @click="handleEdit(scope.row)">查看 <i class="el-icon-search"></i></el-button>
		    </template>
		  </el-table-column>
		</el-table>
		<div style="padding: 10px 0">
		  <el-pagination
		      @size-change="handleSizeChange"
		      @current-change="handleCurrentChange"
		      :current-page="pageNum"
		      :page-sizes="[20]"
		      :page-size="pageSize"
		      layout=""
		      :total="total">
		  </el-pagination>
		</div>
		<el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" >
		  <el-form label-width="80px" size="small">
<!-- 		               <el-form-item label="时间">
		      <el-date-picker v-model="form.getTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
		    </el-form-item> -->
<!-- 		    <el-form-item label="标题">
		      <el-input v-model="form.title" autocomplete="off"></el-input>
		    </el-form-item> -->
		    <el-form-item label="" disabled>
		      <p v-html="this.form.content"></p>
		    </el-form-item>
<!-- 		    <el-form-item label="接收者">
		      <el-input v-model="form.jieshou" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item label="发送者">
		      <el-input v-model="form.fasong" autocomplete="off"></el-input>
		    </el-form-item> -->
		
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
  </div>
</template>

<script>
export default {
  name: "Notice",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
		console.log(this.user)
      this.request.get("/notice/findBy?jieshou=全部&type=新闻公告", {
      }).then(res => {
        this.tableData = res.data
        this.total = res.data.length
      })
    },
    save() {
      this.request.post("/notice", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    del(id) {
      this.request.delete("/notice/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/notice/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open("http://localhost:9090/notice/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    },changeState(obj) {
          this.entity = obj;
          this.save();
      }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
